
































import {
  ref,
  computed,
  useContext,
  useRoute,
  defineComponent,
  useFetch,
  onMounted,
  onBeforeUnmount,
  watch,
  useRouter,
} from "@nuxtjs/composition-api";

import { merge } from "lodash-es";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { SfBreadcrumbs, SfLoader } from "@storefront-ui/vue";
import { getBreadcrumbs } from "~/modules/catalog/product/getters/productGetters";
import { useProduct } from "~/modules/catalog/product/composables/useProduct";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { usePageStore } from "~/stores/page";
import { ProductTypeEnum } from "~/modules/catalog/product/enums/ProductTypeEnum";
import { useWishlist, useApi } from "~/composables";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import type { Product } from "~/modules/catalog/product/types";
import type { ProductDetailsQuery } from "~/modules/GraphQL/types";
import ProductSkeleton from "~/modules/catalog/product/components/ProductSkeleton.vue";
import getProductPriceBySkuGql from "~/modules/catalog/product/queries/getProductPriceBySku.gql";
import CustomBreadcrumbs from "~/components/General/CustomBreadcrumbs.vue";
import { useConfigStore } from "~/stores/config";
import { Icon } from "@iconify/vue2";

export default defineComponent({
  name: "ProductPage",
  components: {
    Icon,
    ProductSkeleton,
    CustomBreadcrumbs,
    SimpleProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/simple/SimpleProduct.vue"
      ),
    BundleProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue"
      ),
    ConfigurableProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/configurable/ConfigurableProduct.vue"
      ),
    GroupedProduct: () =>
      import(
        "~/modules/catalog/product/components/product-types/grouped/GroupedProduct.vue"
      ),
    RelatedProducts: () =>
      import("~/modules/catalog/product/components/RelatedProducts.vue"),
    UpsellProducts: () =>
      import("~/modules/catalog/product/components/UpsellProducts.vue"),
    LoadWhenVisible,
    SfBreadcrumbs,
    SfLoader,
  },
  transition: "fade",
  setup() {
    const { routeData } = usePageStore();
    const router = useRouter();
    const { query } = useApi();
    const product = ref<Product | null>(null);
    const { addTags } = useCache();
    const { localePath } = useContext();
    const route = useRoute();
    const { getProductDetails, loading } = useProduct();
    const { error: nuxtError } = useContext();
    const { load: loadWishlist } = useWishlist();
    const breadcrumbs = computed({
      get: () => {
        const productCategories = product.value?.categories ?? [];
        return getBreadcrumbs(
          product.value,
          Array.isArray(productCategories) ? [...productCategories].pop() : null
        ).map((breadcrumb) => ({
          ...breadcrumb,
          link: localePath(breadcrumb.link),
        }));
      },
      set: () => {},
    });

    const { setMenColor } = useConfigStore();

    onBeforeUnmount(() => {
      setMenColor(false);
    });

    watch(product, () => {
      if (product?.value?.categories?.some((c) => c.name === "TAILOR'S")) {
        setMenColor(true);
      } else {
        setMenColor(false);
      }
    });

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
      configurations: Object.entries(route.value.query)
        .filter((config) => config[0] !== "wishlist")
        .map((config) => config[1]) as string[],
    });

    // eslint-disable-next-line no-underscore-dangle
    const renderer = computed({
      get: () => product.value?.__typename ?? ProductTypeEnum.SIMPLE_PRODUCT,
      set: () => {},
    });

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      const result = await getProductDetails({
        ...searchQuery,
      });

      product.value = merge(
        {},
        product.value,
        (result.items[0] as Product) ?? null
      );
    };

    const fetchProductExtendedData = async (
      searchQuery = getBaseSearchQuery()
    ) => {
      const { data } = await query<ProductDetailsQuery>(
        getProductPriceBySkuGql,
        searchQuery
      );

      const item: any = data.products.items[0];

      if (item.__typename !== "GroupedProduct") {
        product.value = merge(
          {},
          product.value,
          data.products?.items?.[0] as Product
        );
      }
    };

    useFetch(async () => {
      await fetchProductBaseData();

      if (Boolean(product?.value?.sku) === false)
        nuxtError({ statusCode: 404 });

      const tags = [
        {
          prefix: CacheTagPrefix.View,
          value: `product-${routeData.sku}`,
        },
      ];

      const productTags = [
        {
          prefix: CacheTagPrefix.Product,
          value: product.value.uid,
        },
      ];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      addTags([...tags, ...productTags]);
    });

    onMounted(async () => {
      await Promise.all([fetchProductExtendedData(), loadWishlist()]);
    });
    const goToPrev = () => {
      router.back();
    };
    return {
      renderer,
      loading,
      breadcrumbs,
      product,
      fetchProduct: fetchProductExtendedData,
      goToPrev,
    };
  },
  head() {
    return getMetaInfo(this.product);
  },
});
