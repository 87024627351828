











































































































































import LazyHydrate from "vue-lazy-hydration";
import { SfSelect, SfHeading } from "@storefront-ui/vue";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  ssrRef,
  useFetch,
  watch,
  onBeforeUnmount,
  useRoute,
} from "@nuxtjs/composition-api";
import { CacheTagPrefix, useCache } from "@vue-storefront/cache";
import { usePageStore } from "~/stores/page";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import CategoryPagination from "~/modules/catalog/category/components/pagination/CategoryPagination.vue";
import { useCategory, useFacet, useUiHelpers, useUiState } from "~/composables";

import { useAddToCart } from "~/helpers/cart/addToCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { usePrice } from "~/modules/catalog/pricing/usePrice";
import { useCategoryContent } from "~/modules/catalog/category/components/cms/useCategoryContent";
import { useTraverseCategory } from "~/modules/catalog/category/helpers/useTraverseCategory";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import { getMetaInfo } from "~/helpers/getMetaInfo";

import CategoryNavbar from "~/modules/catalog/category/components/navbar/CategoryNavbar.vue";
import CategoryBreadcrumbs from "~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue";

import type { ProductInterface } from "~/modules/GraphQL/types";
import type { SortingModel } from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import type { Pagination } from "~/composables/types";
import type { Product } from "~/modules/catalog/product/types";

import PageTitle from "~/components/General/PageTitle.vue";
import { Icon } from "@iconify/vue2";

import EducationCategoryComp from "../category/components/EducationCategoryComp.vue";
import BackDrop from "~/components/General/BackDrop.vue";
import { useConfigStore } from "~/stores/config";
import { useCategoryStore } from "~/modules/catalog/category/stores/category";

export default defineComponent({
  name: "CategoryPage",
  components: {
    CategoryPagination,
    CategoryEmptyResults: () =>
      import("~/modules/catalog/category/components/CategoryEmptyResults.vue"),
    CategoryFilters: () =>
      import(
        "~/modules/catalog/category/components/filters/CategoryFilters.vue"
      ),
    CmsContent: () =>
      import("~/modules/catalog/category/components/cms/CmsContent.vue"),
    CategoryProductGrid: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductGrid.vue"
      ),
    CategoryProductList: () =>
      import(
        "~/modules/catalog/category/components/views/CategoryProductList.vue"
      ),
    CategoryNavbar,
    CategoryBreadcrumbs,
    SfSelect,
    LazyHydrate,
    SfHeading,
    SkeletonLoader,
    PageTitle,
    Icon,
    EducationCategoryComp,
    BackDrop,
  },
  transition: "fade",
  setup() {
    const { routeData } = usePageStore();
    const { getContentData } = useCategoryContent();
    const { loadCategoryMeta } = useCategory();
    const { addTags } = useCache();
    const uiHelpers = useUiHelpers();
    const cmsContent = ref("");
    const isShowCms = ref(false);
    const isShowProducts = ref(false);
    const products = ssrRef<ProductInterface[]>([]);
    const sortBy = ref<SortingModel>({ selected: "", options: [] });
    const pagination = ref<Pagination>({});
    const { setMenColor } = useConfigStore();
    const categories = useCategoryStore();
    const route = useRoute();

    const productContainerElement = ref<HTMLElement | null>(null);

    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
    } = useUiState();
    const {
      load: loadWishlist,
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();
    const { result, search } = useFacet();
    const { addItemToCart } = useAddToCart();

    const categoryMeta = ref(null);

    const addItemToWishlist = async (product: Product) => {
      await (isInWishlist({ product })
        ? removeItemFromWishlist({ product })
        : addItemToWishlistBase({ product }));
    };

    const { activeCategory, loadCategoryTree } = useTraverseCategory();
    const activeCategoryName = computed(() => activeCategory.value?.name ?? "");

    const categoryUid = routeData.uid;

    const { fetch } = useFetch(async () => {
      if (!activeCategory.value) {
        await loadCategoryTree();
      }

      const [content, categoryMetaData] = await Promise.all([
        getContentData(categoryUid as string),
        loadCategoryMeta({ category_uid: routeData.value?.uid }),
        search({ ...uiHelpers.getFacetsFromURL(), category_uid: categoryUid }),
      ]);

      categoryMeta.value = categoryMetaData;
      cmsContent.value = content?.cmsBlock?.content ?? "";
      isShowCms.value = content.isShowCms;
      isShowProducts.value = content.isShowProducts;

      products.value = facetGetters.getProducts(result.value) ?? [];
      sortBy.value = facetGetters.getSortOptions(result.value);
      pagination.value = facetGetters.getPagination(result.value);

      const tags = [{ prefix: CacheTagPrefix.View, value: routeData.uid }];
      const productTags = products.value.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product.uid,
      }));

      addTags([...tags, ...productTags]);
    });

    const isPriceLoaded = ref(false);

    const doChangeSorting = (sort: string) => {
      uiHelpers.changeSorting(sort, false);
      onReloadProducts();
    };

    onMounted(async () => {
      loadWishlist();
      const { getPricesBySku } = usePrice();
      if (products.value.length > 0) {
        const skus = products.value.map((item) => item.sku);
        const priceData = await getPricesBySku(
          skus,
          pagination.value.itemsPerPage
        );
        products.value = products.value.map((product) => {
          const priceRange = priceData.items.find(
            (item) => item.sku === product.sku
          )?.price_range;

          if (priceRange) {
            return {
              ...product,
              price_range: priceRange,
            };
          }

          return { ...product };
        });
      }

      isPriceLoaded.value = true;
    });
    const scrollToTop = () => {
      let element = document.querySelector("#layout");
      element.scrollIntoView({ behavior: "smooth" });
    };
    const goToPage = (page: number) => {
      scrollToTop();
      uiHelpers.changePage(page, false);
      fetch();
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(0);
    };

    const onReloadProducts = () => {
      goToPage(0);
      if (window?.scroll) {
        window.scroll(0, 0);
      }
    };
    const setNoScrollOnBody = (noScroll) => {
      if (noScroll) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
        toggleFilterSidebar();
      }
    };

    onBeforeUnmount(() => {
      setMenColor(false);
    });

    function doesChildBelongToTopLevel(arr, topLevelUid, childUid) {
      return arr.some((currentObject) => {
        // Check if the current object's name matches the top-level name
        if (currentObject.uid === topLevelUid) {
          // Check if any child's name matches the target child name
          if (currentObject.children) {
            return currentObject.children.some((child) => {
              if (child.uid === childUid) {
                return true; // Found a match
              }

              // Recursively check nested children
              if (
                child.children &&
                doesChildBelongToTopLevel(child.children, topLevelUid, childUid)
              ) {
                return true; // Found a match in nested children
              }

              return false; // No match found for this child
            });
          }
        } else {
          if (currentObject.children && currentObject.children.length > 0) {
            return doesChildBelongToTopLevel(
              currentObject.children,
              topLevelUid,
              childUid
            );
          }
        }

        return false; // No match found for this object
      });
    }

    watch(
      route,
      (val) => {
        if (!activeCategory.value) {
          return;
        }
        let answer = doesChildBelongToTopLevel(
          categories.categories.children,
          "MzM=",
          activeCategory.value.uid
        );
        if (answer || activeCategory.value.uid === "MzM=") {
          setMenColor(true);
        } else {
          setMenColor(false);
        }
      },
      { deep: true }
    );

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      sortBy,
      isShowCms,
      isShowProducts,
      cmsContent,
      activeCategoryName,
      routeData,
      doChangeItemsPerPage,
      productContainerElement,
      categoryMeta,
      onReloadProducts,
      goToPage,
      activeCategory,
      doChangeSorting,
      setNoScrollOnBody,
    };
  },
  head() {
    return getMetaInfo(this.categoryMeta);
  },
});
